
import { createGlobalStyle } from 'styled-components';
import './flaticon.css';
import './stylefont.css';
import './stylelt.css';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  html {
    scroll-behavior: smooth;
  }

  body {
     /*font-family: Avenir Next LT Pro Regular !important;*/
     font-family: 'Poppins', sans-serif !important;
     color:#10185a !important;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif !important;
}

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;
    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }

  .form_container{
      background-color: #f7f8fd;
  }
  .anvragen_page {
      background-color: #fff !important;
  }
  

.glide__next--area {
    position: absolute;
    right: 0px !important
    top: 300px;
}
.glide__next--area button {
    background-color: transparent;
    border: 0px;
    background-image: url(https://cdn2.hubspot.net/hubfs/53/assets/hubspot.com/product_pages/get-started/Left.svg);
    background-repeat: no-repeat;
    font-size: 0;
    width: 17px;
    height: 29px;
    background-size: cover;
    position: absolute;
}
.glide__prev--area {
    position: absolute;
    left: -25px !important
    top: 300px;
}
.glide__prev--area button {
    background-color: transparent;
    border: 0px;
    background-image: url(https://cdn2.hubspot.net/hubfs/53/assets/hubspot.com/product_pages/get-started/Left.svg);
    background-repeat: no-repeat;
    font-size: 0;
    width: 17px;
    height: 29px;
    background-size: cover;
    position: absolute;
    transform: rotate(180deg);
}
.glide__prev--area button:focus {
    outline: 0;
}
.glide__next--area button:focus {
    outline: 0;
}

.displaynone {
    display : none;
}
.blue-bg .container { max-width: 1340px; }
.blue-bg { background-position: top center; background-repeat: no-repeat; /*background: #ffffff; 
background: -moz-linear-gradient(top, #ffffff 0%, #ffffff 49%, #f1f1f1 50%, #f1f1f1 100%);
background: -webkit-linear-gradient(top, #ffffff 0%,#ffffff 49%,#f1f1f1 50%,#f1f1f1 100%); 
background: linear-gradient(to bottom, #ffffff 0%,#ffffff 49%,#f1f1f1 50%,#f1f1f1 100%); 
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f1f1f1',GradientType=0 ); */
 width: 100%; padding:40px 0; }
.left-sidebar { padding:24px 18px; -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10); height: 100%; border-radius: 5px; background-color: #fff;  }
.left-sidebar h3 { font-size: 20px; font-weight: 700; margin-bottom: 10px; padding-top:8px; }
.left-sidebar h3 a { color:#10185a; }
.price-tag { width: 14px; height: 14px; }
.svg-inline--fa { vertical-align: middle; }
.left-sidebar b { font-size: 24px; font-family: 'Poppins', sans-serif; margin-right: 10px; }
.client-info { display: flex; flex-wrap:wrap; margin-bottom: 5px;}
.client-info li { margin:3px 5px 3px 0; display: flex; align-items: center; line-height: 17px; background-color: #2abe79; font-family: 'Poppins', sans-serif; font-weight: 900; letter-spacing: 1px; color:#fff; font-size: 12px; padding:5px; border-radius: 5px; text-transform: uppercase;  }
.form-wrapper {  border-radius: 5px; background-color: #fff; -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10); padding-top:30px;
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10); float: left; width:100%; }
.form-wrapper .close-popup { position: absolute; right: 40px;  top:0; color:#10185a; top:20px; font-size: 20px; } 
.form-wrapper .progress { height: 0.40rem; border-radius: 0;  }
.progressBar { width: 50%; margin:0px auto; }
.form-wrapper .progress-bar { background-color: #FE6700; }
.mainFormContent {  height: 67vh; overflow-y: auto; padding:40px 50px 40px 50px; }
.mainFormContent h2 { font-size: 24px; line-height: 34px; padding:20px 0 10px 0; font-weight: 900; text-align: center;  }
.mainFormContent .RequestFormLocation { max-width: 300px; margin:0px auto; }
.mainFormContent .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{padding:0px 0 0 15px;}
.mainFormContent .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input{
  padding:1px 10px 1px 1px; border-radius:4px; border:0px;}
.RequestFormLocation input { border-left:0 !important; }
.mainFormContent input { height: 48px; border:1px solid #b6b9cd; }
.form-validation input { position: relative; }
.validInput { font-size: 21px; position: absolute; right:10px; top:42px; color: #2abe79;  }
.mainFormContent input:focus, .mainFormContent textarea:focus { box-shadow: none; border-color:#b6b9cd; }
.descriptionForm textarea { border:1px solid #b6b9cd ; min-height: 200px; }
.mainFormContent .input-group-text { border:1px solid #b6b9cd ; border-right: 0; background-color: #fff; }
.form-bottom { border-top:1px solid #e5e5e5; display: flex; align-items: center; justify-content: space-between; padding:30px 50px; float: left; width:100%; }
.mainFormContent small { font-size: 14px; font-weight: 700; display: block; margin-bottom: 10px; }
.orange-btn { background-color: #FE6700 !important; border:2px solid transparent !important; font-size: 18px !important; font-weight: 600 !important; height: 52px; padding:5px 30px !important; font-family: 'Poppins', sans-serif; color:#fff !important;  }
.orange-btn:focus { box-shadow:0 0 0 0.2rem rgba(254,103,0,.5) !important;  }
.transparent-btn { background-color: transparent !important; font-weight: 400 !important; color:#10185a !important; }
.border-btn {background-color: transparent !important; border:1px solid #b6b9cd !important; color:#10185a !important;  font-weight: 600 !important;  }
.border-btn:focus { box-shadow:none !important;  }
.border-btn:hover { background-color:rgba(87,136,255,.06) !important; }
.transparent-btn:focus { box-shadow:none !important; }
.small-heading { text-align: center; font-weight: 500; display: block; color:#5788ff; }
.form-bottom-no-border { justify-content: center; border:0; padding:60px 50px; }
.mainFormContent .form-check { padding-left: 0; margin-bottom: 15px;  }
.centerForm { max-width: 280px; margin:0px auto; }
.descriptionForm { max-width:600px; margin:0px auto; }
.descriptionForm h2 { text-align: left; font-size: 22px; }
.rating-form { max-width: 600px; margin:0px auto; padding-top:25px; }
.rating small { font-size: 15px; margin-bottom: 0px; }
.rating { margin-bottom: 15px; align-items: center; }
.rating b { font-size: 22px; }
.sentScreen h3 { color:#5788ff; font-size: 24px; font-weight:900; text-align: center; }
.sentScreen h2 { padding-top:0; }
.sent-img { width: 100px; }
.mailsent { width: 80px; height: 80px; }
.fullrating { display: flex; }
.fullrating .fa-check { font-size: 12px; }
.sentrating { box-shadow:0 2px 8px 0 rgba(49,54,103,.1); overflow: hidden; display: flex; align-items: center; }
.sentrating .client-info { flex-wrap: nowrap; }
.sentratingList { padding:5px 8px; }
.sentrating li { white-space: nowrap; }
.customForm  em {display: block; letter-spacing: 1px; font-weight: 700; margin-bottom: 8px; font-style: normal; font-size: 14px;  }
.input-text { float: right; opacity: .5; font-size: 12px; padding-top:6px; font-weight: 600; }
.mainFormContent .form-check label { font-weight: 500; cursor: pointer;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.2);
    border-radius: 5px; padding:5px 16px 5px 44px; width: 100%; display: flex; align-items: center; min-height: 65px; }
.mailbox-img { margin:50px auto 0 auto; width: 300px; }
.lock-icon-text img { width: 13px; height: 16px; margin-right: 10px; }
.lock-icon-text p { font-size: 13px; }
.form-bottom p, .button-text { font-size: 12px; margin-bottom: 0; font-weight: 600; margin-top:7px; }
.lock-icon-text a { text-decoration: underline; color:#10185a; }
.customForm { margin-top:25px; }
.mainFormContent .form-check input[type='radio'], .mainFormContent .form-check input[type='checkbox'] {
    -webkit-appearance: none;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    outline: none;
    border: 4px solid #10185a;
    top: 16px;
    margin-left: 15px;
}
.mainFormContent .form-check input[type='radio']:before, .mainFormContent .form-check input[type='checkbox']:before {
    content: '';
    display: block;
    width: 13px;
    height: 100%;
    margin: 0;
    border-radius: 50%;
}
.mainFormContent .form-check input[type="radio"]:checked:before , .mainFormContent .form-check input[type="checkbox"]:checked:before {
    background: #fff; 
}

.mainFormContent .form-check input[type="radio"]:checked, .mainFormContent .form-check input[type="checkbox"]:checked {
  border-color: #fff;
}
.mainFormContent .form-check input[type="radio"]:checked ~ label, .mainFormContent .form-check input[type="checkbox"]:checked ~ label {
  background-color: #FE6700; color:#fff;
}

.dot-list li { position: relative; }
.dot-list li:before { content: "●"; top: 1px; left: 0; margin-right: 5px; line-height: 1; }
.add-more { margin-top:35px; }
.add-more a { color: #5788ff; font-weight: 600; font-size: 17px; }
.add-more a:hover { color:#10185a; }


//Custom changes

.dot-img-outer{
  width:300px;
  position:relative;
  margin:0 auto

}
 .text{
  color:#2745C4;
  font-size:18px
}
.pulsatingDot {
  animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  background-color: var(--pulsating-dot, #00BEFF);
  border-radius: 50%;
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  position: absolute;
    top: 15x;
    right: 0;
    bottom: 0;
    left: 145px;
    top: 110px;
}

.pulsatingDot:before {
  animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  background-color: var(--pulsating-dot, #00BEFF);
  border-radius: 45px;
  content: '';
  display: block;
  height: 300%;
  left: -100%;
  position: relative;
  top: -100%;
  width: 300%;
}  

@keyframes pulseRing {
  0% { transform: scale(.5); }
  80%, 100% { opacity: 0; }
}

@keyframes pulseDot {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}


//Left side bar changes
.sidebar-footer-outer{
 
  padding: 20px 10px;
    float: left;
    width: 100%;
    margin-top: 75px;
}
.sidebar-footer-inner{
  background-color: #fff;
    position: relative;
    margin: 10px 15px;
    text-align: center;
    padding: 30px 0px;
}
.sidebar-footer-inner img{
  height:150px;
  width:150px;
}
.sidebar-footer-inner span{
  font-size:12px;
}
.pulsatingDot{
  animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  background-color: var(--pulsating-dot, #FE6700);
  border-radius: 50%;
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  position: absolute;
    top: 15x;
    right: 0;
    bottom: 0;
    left: 150px;
    top: 110px;
}

.pulsatingDot:before{
  animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  background-color: var(--pulsating-dot, #FE6700);
  border-radius: 45px;
  content: '';
  display: block;
  height: 300%;
  left: -100%;
  position: relative;
  top: -100%;
  width: 300%;
}

.sidebar-footer-inner .pulsatingDot{
  animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  background-color: var(--pulsating-dot, #FE6700);
  border-radius: 50%;
  box-sizing: border-box;
  height: 10px;
  width: 10px;
  position: absolute;
    top: 15x;
    right: 0;
    bottom: 0;
    left: 120px;
    top: 110px;

}
.sidebar-footer-inner .pulsatingDot:before{
  animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  background-color: var(--pulsating-dot, #FE6700);
  border-radius: 45px;
  content: '';
  display: block;
  height: 300%;
  left: -100%;
  position: relative;
  top: -100%;
  width: 300%;
}

.red-text-color{
  color:#F24726
}
.form-one-search{
  width: 300; 
  position:absolute;
   right:300;
    top:120;
     bottom:0;
}

//Loader Css

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader {
  margin: 0px auto;
  font-size: 10px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255,255,255, 0.8);
  border-right: 1.1em solid rgba(255,255,255, 0.8);
  border-bottom: 1.1em solid rgba(255,255,255, 0.8);
  border-left: 1.1em solid #ddd;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  position: absolute;
  left: 45%;
  top: 20% !important;
  transform: translate(-50%, -50%);
}
.loader-min {
  position: absolute;
  width: 100%;
  height: 100%;
  background:#fff;
  z-index: 1500;
  top: 0;
  height:100vh;
  background-color: #fff;
}


@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.modal-content{
  background-color:transparent !important;
  border:0 !important;
  position:relative

}
.modal-header {
  display: block !important;
  padding:0px !important;
  border:none !important;
}
.modal-header .close {
  position: absolute !important;
    
    right: 30px !important;
    top: 15px;
}
.modal-header .close:focus {
  outline:none;
}


.modal-close .modal-content{
  max-width: 723px;
  float: right;
  background-color: #fff !important;
  margin-top: 35px;
}
.modal-close .modal-header button{
 
    top: -20px;
    right: 15px !important;
}
.modal-close .form-bottom-no-border{
  padding-top:30px
}

.toggle-button {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
}
.orange-btn-link{
  padding:8px 30px !important;
}

.product-m-div{
  max-width:450px;
  width:100%;
  margin:0px auto;
}
.prduct-m-img {
  background:#fff;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
    box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.10);
  margin: 20px 10px;
  width:185px;
  float:left;
}
.prduct-m-img strong{
   color:#204ECF;
   font-size:14px;
   display:block
}
.prduct-m-img span{
   color:#4E4E4E;
   font-size:13px;
   display:block
}
.product-m-title {
   padding:13px 20px 13px 30px;
}
.animate-search{
    width: 300px;
    position: absolute;
    top: 173px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(60,64,67,.3), 0 4px 8px 3px rgba(60,64,67,.15);
    border-radius: 8px;

}
.animate-search:hover{
  border-color:#C4C4C4;
}
.RequestFormLocation .animate-search .mainFormContent input {
   border:none !important; 
}
.mainFormContent .animate-search input{
  height:26px !important;
}
.popover-container {
  width: 300px;
  margin: 0px auto;
  position: relative;
}
.popover {
  background: #fff;
  border-radius: 4px;
  width: 300px;
  margin: 20px auto;
  padding: 15px;
  position: absolute;
  top: 100%;
  left: -50px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border:none;
  opacity:0;
  transition: all .5s ease;

}
.popover:hover{
  border-color:#C4C4C4;
}

.popover .MuiInputBase-root.MuiOutlinedInput-root:hover {
  border:0 !important;
}

.show{
opacity:1;
}

.popover .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border:0 !important;
}

.popover .MuiOutlinedInput-notchedOutline {
  border:0;
}

.animate-search .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 5px 4px !important;
}
.RequestFormLocation .animate-search input {
  border-left: 0 !important;
  border-top: 0 !important;
  border-bottom:0 !important;
  border-right:0 !important;
}

.RequestFormLocation .animate-search .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: none;
}
.RequestFormLocation .MuiIconButton-label{
  display:none;
}
.popover .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: none !important;
  border-width:0 !important;
}
.popover .MuiOutlinedInput-root MuiAutocomplete-inputRoot:hover {
 border:none !important
}

.MuiAutocomplete-root.popover {
  width:100% !important;
  max-width:300px;
  margin:0 auto;
  top:0;
  left:0;
  right:0;
  padding-top:0;
  padding-left:0;
  padding-bottom:0;
  transition: all 5s ease !important;
}

.MuiAutocomplete-root.popover .MuiInputBase-input {
  border:0;
}

.MuiAutocomplete-root.popover .MuiOutlinedInput-root {
  padding-top:0;
  padding-bottom:0;
}

.MuiAutocomplete-root.popover .MuiOutlinedInput-root .MuiInputBase-input {
  padding-top:0;
  paddding-bottom:0;
}
.destop-logo{
  display:none;
}

.custom-btn.btn-primary:focus, 
.custom-btn.btn-primary:visible, 
.custom-btn.btn-primary:within {
  box-shadow:0px !important;
  outline:none!important;
  border:0px;
}
.custom-btn.btn.btn-primary:focus{
  box-shadow:none !important;
}
.custom-btn{
  background: transparent;
  color: #10185a;
  border: 0px;
  padding: 0px;
  margin:0px auto;
  display:block;
  text-decoration: underline;
}

.custom-btn.btn-primary:hover{
  background: transparent!important;
  color: #2745C4 !important;
  box-shadow:none;
  color: #10185a !important;
  text-decoration: underline !important;
}

@media (min-width: 576px){
  .modal-dialog {
      max-width: 1100px !important;
      margin: 1.75rem auto;
  }
 
}
@media (max-width:991px) {
  .mainFormContent { padding:20px 15px; }
  .mainFormContent .form-check label { font-size: 14px; }
  }
@media (max-width:767px) {
  .left-sidebar { display: none; }
  .mainFormContent h2 { font-size: 18px;line-height: 26px; }
  .form-bottom { padding:15px;  }
  .orange-btn { font-size: 14px !important; padding:5px 11px !important; }
  .mainFormContent .form-check input[type='radio'] { top:10px;  }
  .mainFormContent .form-check label { min-height: 50px; }
  .destop-logo{
    display:block;
  }
}

.closeContent {
  ul {
    display: inline-block

    li {
      list-style: unset
    }
  }
}

.modeldesign {
  margin: 1.75rem auto;
  max-width: 1100px !important;
}

`;
